import { unionState } from "@/js/store/helpers"

const state = () => ({
  items: [],
})

const actions = {
  async fetch({ commit, state }, id) {
    let listing = state.items.find((i) => i.id == id)

    if (!listing) {
      const response = await axios.get(`/job-listings/${id}`)
      listing = response.data
    }
    commit("storeItem", listing)
    return listing
  },

  async apply({}, { id, params }) {
    await axios.post(`/job-listings/${id}/apply`, params)
  },
}

const mutations = {
  storeItem(state, item) {
    state.items = unionState(state.items, [item])
  },
}

const getters = {
  getById: (state) => (id) => state.items.find((b) => b.id === id),
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
