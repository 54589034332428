import presentationSlides from "@presentation/js/translations/fr/slides.js"
import presentationAssumptions from "@presentation/js/translations/fr/assumptions.js"
import months from "./months"
import charts from "./charts"
import colour from "./colour"
import pagination from "@common/js/translations/fr/pagination"
import hardwareEquipmentPicker from "@common/js/translations/fr/dialogs/hardware-equipment-picker"

export default {
  yes: "Oui",
  no: "Non",
  copyright: "2024 © projetsolaire. Tous droits réservés.",
  warning: "Alerte !",
  title: "Votre titre",
  name: "Votre nom",
  "first-name": "Votre prénom",
  "last-name": "Votre nom",
  "enter-your-name": "Entrez votre nom",
  "enter-your-first-name": "Entrez votre prénom",
  "enter-your-last-name": "Entrez votre nom",
  email: "Courriel",
  "enter-your-email": "Entrez votre email",
  "your-email": "Entrez votre email",
  password: "Mot de passe",
  "enter-your-password": "Entrez votre mot de passe",
  siret: "Votre numéro SIRET",
  "enter-your-siret": "Entrez votre SIRET",
  "phone-number": "Votre numéro de téléphone",
  "enter-your-phone-number": "Entrez votre numéro de téléphone",
  "sign-up": "Inscription",
  "sign-in": "Connexion",
  loading: "Chargement",
  files: "Fichiers",
  or: "Ou",
  apply: "Postuler",
  optional: "Facultatif",
  browse: "Parcourir",
  "allowed-formats": "Formats autorisés",
  "maximum-size": "Jusqu'à %{size} Mb",
  "no-file-selected": "Aucun fichier sélectionné",
  buttons: {
    "color-picker": "Choisir une couleur",
    "learn-more": "En savoir plus",
    continue: "Continuer",
    clear: "Réinitialiser",
    search: "Rechercher",
    preview: "Aperçu",
    previous: "Précédent",
    next: "Suivant",
    cancel: "Annuler",
    add: "Ajouter",
    back: "Retour",
    download: "Télécharger",
    remove: "Retirer",
    save: "Sauvegarder",
    export: "Exporter",
    upload: "Charger",
    "upload-documents": {
      title: "Chargez un fichier ou glissez et déposez",
    },
    update: "Mettre à jour",
    view: "Visualiser",
    edit: "Modifier",
    delete: "Supprimer",
    create: "Créer",
    "copy-link": "Copier le lien",
    send: "Envoyer",
    reset: "Réinitialiser",
    copy: "Copier",
    "add-issues": "Ajouter problématique",
    "add-revisions": "Ajouter demande de pièces",
    more: "Voir plus",
    "view-changes": "Visualiser modifications",
    "add-team-member": "Ajouter co-équipier",
    "open-options": "Ouvrir menu des options",
    "view-grid": "Affichier au format grille",
    products: "Produits",
    test: "Essayer",
    proceed: "Poursuivre",
    regenerate: "Régénérer",
    submit: "Soumettre",
    confirm: "Confirmer",
    "remove-all": "Tout effacer",
    select: "Sélectionner",
    "please-select": "Veuillez sélectionner",
    valid: "Valider",
  },

  "select-a-tab": "Sélectionner un onglet",
  titles: {
    mr: "Monsieur",
    mrs: "Madame",
  },
  "user-types": {
    BUSINESS: "Industriel",
    RESIDENTIAL: "Résidentiel",
  },

  processing: "Chargement...",
  loader: {
    loading: "Chargement",
  },
  "upload-doc-dialog": {
    "upload-file": "Télécharger fichier",
    "or-drag-and-drop": "ou glisser-déposer",
    img: "JPG jusqu'à 10MB ",
    pdf: "PDF jusqu'à 10MB ",
    "pdf-and-img": "PDF,JPG jusqu'à 10MB",
  },
  "cookie-gdpr": {
    "cookie-text-1":
      "Nous utilisons les cookies pour mettre nos services à votre disposition.",
    "cookie-text-2": "Pour en savoir, veuillez explorer notre ",
    "cookie-link": "Déclaration de Confidentialité",
    accept: "Accepter",
    decline: "Refuser",
  },
  "algolia-search-dialog": {
    search: "Rechercher",
    placeholder: "Rechercher...",
    results: "Résultats",
    tags: {
      installer: "Installateur",
      householder: "Propriétaire",
      "help-center": "Centre d'aide",
      blog: "Blog",
    },
  },
  presentation: {
    preview: {
      slides: presentationSlides,
      assumptions: presentationAssumptions,
    },
  },
  "hardware-equipment-picker": hardwareEquipmentPicker,
  "not-signed-in": {
    title: "Accès refusé",
    description: "Vous devez vous connecter pour accéder à cette page",
    button: "Page de connexion",
  },
  verifying: {
    title: "Vérification",
    title2: "Nous vérifions qu'il s'agit bien de vous",
    description:
      "Nous sommes en train de vérifier votre accès, veuillez patienter.",
  },
  error: {
    title: "Oups, il y a eu un problème",
    button: "Retour à l'accueil",
  },
  success: {
    "reset-password": "Réinitialisation du mot de passe réussie",
    "sign-in": "Se connecter",
    continue: "Continuer",
  },
  "page-not-found": {
    title: "Cette page n'existe pas.",
    description: "La page que vous recherchez n'a pu être trouvée.",
    "go-home": "Retourner à l'accueil",
  },
  "newsletter-dialog": {
    title: "Rejoignez le bulletin projetsolaire",
    description:
      "Inscrivez-vous à la lettre d'information du bureau d'étude, avec actualités, mises à jour, exclusivités",
    form: {
      "first-name": "Prénom",
      "last-name": "Nom",
      company: "Entreprise",
      email: "Email",
    },
    "agree-to-policies": "Accepter les politiques",
    "switch-agree": "En sélectionnant cette option, vous acceptez notre",
    "privacy-policy": "politique de confidentialité",
    subscribe: "S'abonner",
  },

  forms: {
    address: {
      search: {
        results: "Résultats",
        placeholder: "Rechercher",
        select: "Sélectionner",
        "not-found": {
          title: "Aucun résultat n'a été trouvé",
          subtitle:
            "Nous n'avons rien trouvé à cette adresse. Veuillez réessayer.",
        },
        info: "Afin de continuer, veuillez sélectionner une adresse affichée par Google Maps. Par la suite, vous pourriez modifier le point GPS et l’adresse. </br> </br> Google mélange parfois les adresses et les point GPS. L'interface sur la prochaine page permet de repositionner la balise ou modifier les informations détenues par Google. ",
      },
      confirm: {
        title: "Adresse",
        "first-name": "Prénom",
        "last-name": "Nom",
        email: "Email",
        country: "Pays",
        number: "Numéro",
        "street-address": "Adresse",
        city: "Commune",
        state: "Région",
        district: "Département",
        "postal-code": "Code postal",
        "back-to-search": "Retour à la recherche",
        "confirm-the-address": "Confirmer l'adresse",
        subtitle: "Veuillez confirmer l'adresse.",
        tooltip: "Vous pouvez déplacer le curseur sur le point souhaité",
        latitude: "Latitude",
        longitude: "Longitude",
      },
    },
  },

  months,
  colour,
  charts,
  interval: {
    name: "Intervalle",
    hourly: "Heures",
    daily: "Jours",
    monthly: "Mois",
    annually: "Années",
  },
  units: {
    year: "an",
    years: "ans",
    kWh: "Mesures d'électricité (kWh)",
    eur: "euro (€)",
  },
  pagination,
  "confirm-dialog-mandate": {
    title: "Confirmer votre autorisation d’analyse de compteur Linky",
    description:
      "Cliquer sur Confirmer pour valider votre partage de mesures horaires.",
  },
}
