import { createRouter, createWebHistory } from "vue-router"
import { useHead } from "@unhead/vue"
import i18n from "./i18n"

const history = createWebHistory("/")
const routes = [
  {
    path: "/:lang(en|fr)?",
    name: "HomeParent",
    children: [
      {
        path: "",
        alias: "home",
        name: "Home",
        component: () => import("@/js/pages/Home.vue"),
        meta: {
          title: "Home.title",
          description: "Home.description",
        },
      },
      {
        path: "lead-flow",
        name: "LeadFlow",
        component: () => import("@/js/pages/LeadFlow.vue"),
        meta: {
          title: "LeadFlow.title",
          description: "LeadFlow.description",
        },
      },
      {
        path: "about",
        name: "About",
        component: () => import("@/js/pages/About.vue"),
        meta: {
          title: "About.title",
          description: "About.description",
        },
      },
      {
        path: "help",
        name: "HelpParent",
        component: () => import("@/js/pages/help/Index.vue"),
        children: [
          {
            path: "",
            name: "HelpHome",
            component: () => import("@/js/pages/help/Home.vue"),
            meta: {
              title: "HelpHome.title",
              description: "HelpHome.description",
            },
          },
          {
            path: "householder",
            name: "HelpHouseholder",
            component: () => import("@/js/pages/help/householder/Index.vue"),
            meta: {
              title: "HelpHouseholder.title",
              description: "HelpHouseholder.description",
            },
          },
          {
            path: "installer",
            name: "HelpInstaller",
            component: () => import("@/js/pages/help/installer/Index.vue"),
            meta: {
              title: "HelpInstaller.title",
              description: "HelpInstaller.description",
            },
          },
          {
            path: "guides",
            name: "HelpGuide",
            children: [
              {
                path: "installers",
                name: "HelpGuideInstaller",
                // this can be simplifieid by some wildcard for the type of guide
                children: [
                  {
                    path: "your-account",
                    name: "HelpGuideInstallerYourAccount",
                    component: () => import("@/js/pages/help/GuideParent.vue"),
                    props: { contentfulTag: "installerAccountGuide" },
                    children: [
                      {
                        path: "",
                        name: "HelpGuideInstallerYourAccountGuideParent",
                        children: [
                          {
                            path: "all",
                            name: "HelpGuideInstallerYourAccountGuideParentAll",
                            component: () =>
                              import("@/js/pages/help/GuideArticles.vue"),
                          },
                          {
                            path: "",
                            name: "HelpGuideInstallerYourAccountGuideParentArticleParent",
                            component: () =>
                              import("@/js/pages/help/Guide.vue"),
                            children: [
                              {
                                path: ":slug?",
                                name: "HelpGuideInstallerYourAccountArticle",
                                component: () =>
                                  import("@/js/pages/help/Article.vue"),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "presentation",
                    name: "HelpGuideInstallerPresentation",
                    component: () => import("@/js/pages/help/GuideParent.vue"),
                    props: { contentfulTag: "installerPresentationGuide" },
                    children: [
                      {
                        path: "",
                        name: "HelpGuideInstallerPresentationGuideParent",
                        children: [
                          {
                            path: "all",
                            name: "HelpGuideInstallerPresentationGuideParentAll",
                            component: () =>
                              import("@/js/pages/help/GuideArticles.vue"),
                          },
                          {
                            path: "",
                            name: "HelpGuideInstallerPresentationGuideParentArticleParent",
                            component: () =>
                              import("@/js/pages/help/Guide.vue"),
                            children: [
                              {
                                path: ":slug?",
                                name: "HelpGuideInstallerPresentationArticle",
                                component: () =>
                                  import("@/js/pages/help/Article.vue"),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "quoting",
                    name: "HelpGuideInstallerQuoting",
                    component: () => import("@/js/pages/help/GuideParent.vue"),
                    props: { contentfulTag: "installerQuotingGuide" },
                    children: [
                      {
                        path: "",
                        name: "HelpGuideInstallerQuotingGuideParent",
                        children: [
                          {
                            path: "all",
                            name: "HelpGuideInstallerQuotingGuideParentAll",
                            component: () =>
                              import("@/js/pages/help/GuideArticles.vue"),
                          },
                          {
                            path: "",
                            name: "HelpGuideInstallerQuotingGuideParentArticleParent",
                            component: () =>
                              import("@/js/pages/help/Guide.vue"),
                            children: [
                              {
                                path: ":slug?",
                                name: "HelpGuideInstallerQuotingArticle",
                                component: () =>
                                  import("@/js/pages/help/Article.vue"),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "administration",
                    name: "HelpGuideInstallerAdministration",
                    component: () => import("@/js/pages/help/GuideParent.vue"),
                    props: { contentfulTag: "installerAdministrationGuide" },
                    children: [
                      {
                        path: "",
                        name: "HelpGuideInstallerAdministrationGuideParent",
                        children: [
                          {
                            path: "all",
                            name: "HelpGuideInstallerAdministrationGuideParentAll",
                            component: () =>
                              import("@/js/pages/help/GuideArticles.vue"),
                          },
                          {
                            path: "",
                            name: "HelpGuideInstallerAdministrationGuideParentArticleParent",
                            component: () =>
                              import("@/js/pages/help/Guide.vue"),
                            children: [
                              {
                                path: ":slug?",
                                name: "HelpGuideInstallerAdministrationArticle",
                                component: () =>
                                  import("@/js/pages/help/Article.vue"),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "hardware",
                    name: "HelpGuideInstallerHardware",
                    component: () => import("@/js/pages/help/GuideParent.vue"),
                    props: { contentfulTag: "installerHardwareGuide" },
                    children: [
                      {
                        path: "",
                        name: "HelpGuideInstallerHardwareGuideParent",
                        children: [
                          {
                            path: "all",
                            name: "HelpGuideInstallerHardwareGuideParentAll",
                            component: () =>
                              import("@/js/pages/help/GuideArticles.vue"),
                          },
                          {
                            path: "",
                            name: "HelpGuideInstallerHardwareGuideParentArticleParent",
                            component: () =>
                              import("@/js/pages/help/Guide.vue"),
                            children: [
                              {
                                path: ":slug?",
                                name: "HelpGuideInstallerHardwareArticle",
                                component: () =>
                                  import("@/js/pages/help/Article.vue"),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "payments",
                    name: "HelpGuideInstallerPayments",
                    component: () => import("@/js/pages/help/GuideParent.vue"),
                    props: { contentfulTag: "installerPaymentsGuide" },
                    children: [
                      {
                        path: "",
                        name: "HelpGuideInstallerPaymentsGuideParent",
                        children: [
                          {
                            path: "all",
                            name: "HelpGuideInstallerPaymentsGuideParentAll",
                            component: () =>
                              import("@/js/pages/help/GuideArticles.vue"),
                          },
                          {
                            path: "",
                            name: "HelpGuideInstallerPaymentsGuideParentArticleParent",
                            component: () =>
                              import("@/js/pages/help/Guide.vue"),
                            children: [
                              {
                                path: ":slug?",
                                name: "HelpGuideInstallerPaymentsArticle",
                                component: () =>
                                  import("@/js/pages/help/Article.vue"),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "project-management",
                    name: "HelpGuideInstallerProjectManagement",
                    component: () => import("@/js/pages/help/GuideParent.vue"),
                    props: { contentfulTag: "installerProjectManagementGuide" },
                    children: [
                      {
                        path: "",
                        name: "HelpGuideInstallerProjectManagementGuideParent",
                        children: [
                          {
                            path: "all",
                            name: "HelpGuideInstallerProjectManagementGuideParentAll",
                            component: () =>
                              import("@/js/pages/help/GuideArticles.vue"),
                          },
                          {
                            path: "",
                            name: "HelpGuideInstallerProjectManagementGuideParentArticleParent",
                            component: () =>
                              import("@/js/pages/help/Guide.vue"),
                            children: [
                              {
                                path: ":slug?",
                                name: "HelpGuideInstallerProjectManagementArticle",
                                component: () =>
                                  import("@/js/pages/help/Article.vue"),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "post-installation",
                    name: "HelpGuideInstallerPostInstallation",
                    component: () => import("@/js/pages/help/GuideParent.vue"),
                    props: { contentfulTag: "installerPostInstallationGuide" },
                    children: [
                      {
                        path: "",
                        name: "HelpGuideInstallerPostInstallationGuideParent",
                        children: [
                          {
                            path: "all",
                            name: "HelpGuideInstallerPostInstallationGuideParentAll",
                            component: () =>
                              import("@/js/pages/help/GuideArticles.vue"),
                          },
                          {
                            path: "",
                            name: "HelpGuideInstallerPostInstallationGuideParentArticleParent",
                            component: () =>
                              import("@/js/pages/help/Guide.vue"),
                            children: [
                              {
                                path: ":slug?",
                                name: "HelpGuideInstallerPostInstallationArticle",
                                component: () =>
                                  import("@/js/pages/help/Article.vue"),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "legal",
                    name: "HelpGuideInstallerLegal",
                    component: () => import("@/js/pages/help/GuideParent.vue"),
                    props: { contentfulTag: "installerLegalGuide" },
                    children: [
                      {
                        path: "",
                        name: "HelpGuideInstallerLegalGuideParent",
                        children: [
                          {
                            path: "all",
                            name: "HelpGuideInstallerLegalGuideParentAll",
                            component: () =>
                              import("@/js/pages/help/GuideArticles.vue"),
                          },
                          {
                            path: "",
                            name: "HelpGuideInstallerLegalGuideParentArticleParent",
                            component: () =>
                              import("@/js/pages/help/Guide.vue"),
                            children: [
                              {
                                path: ":slug?",
                                name: "HelpGuideInstallerLegalArticle",
                                component: () =>
                                  import("@/js/pages/help/Article.vue"),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                path: "householders",
                name: "HelpGuildeHouseholders",
                children: [
                  {
                    path: "your-account",
                    name: "HelpGuideHouseholderYourAccount",
                    component: () => import("@/js/pages/help/GuideParent.vue"),
                    props: { contentfulTag: "householderAccountGuide" },
                    children: [
                      {
                        path: "",
                        name: "HelpGuideHouseholderYourAccountGuideParent",
                        children: [
                          {
                            path: "all",
                            name: "HelpGuideHouseholderYourAccountGuideParentAll",
                            component: () =>
                              import("@/js/pages/help/GuideArticles.vue"),
                          },
                          {
                            path: "",
                            name: "HelpGuideHouseholderYourAccountGuideParentArticleParent",
                            component: () =>
                              import("@/js/pages/help/Guide.vue"),
                            children: [
                              {
                                path: ":slug?",
                                name: "HelpGuideHouseholderYourAccountArticle",
                                component: () =>
                                  import("@/js/pages/help/Article.vue"),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "payments",
                    name: "HelpGuideHouseholderPayments",
                    component: () => import("@/js/pages/help/GuideParent.vue"),
                    props: { contentfulTag: "householderPaymentsGuide" },
                    children: [
                      {
                        path: "",
                        name: "HelpGuideHouseholderPaymentsGuideParent",
                        children: [
                          {
                            path: "all",
                            name: "HelpGuideHouseholderPaymentsGuideParentAll",
                            component: () =>
                              import("@/js/pages/help/GuideArticles.vue"),
                          },
                          {
                            path: "",
                            name: "HelpGuideHouseholderPaymentsGuideParentArticleParent",
                            component: () =>
                              import("@/js/pages/help/Guide.vue"),
                            children: [
                              {
                                path: ":slug?",
                                name: "HelpGuideHouseholderPaymentsArticle",
                                component: () =>
                                  import("@/js/pages/help/Article.vue"),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "presentation",
                    name: "HelpGuideHouseholderPresentation",
                    component: () => import("@/js/pages/help/GuideParent.vue"),
                    props: { contentfulTag: "householderPresentationGuide" },
                    children: [
                      {
                        path: "",
                        name: "HelpGuideHouseholderPresentationGuideParent",
                        children: [
                          {
                            path: "all",
                            name: "HelpGuideHouseholderPresentationGuideParentAll",
                            component: () =>
                              import("@/js/pages/help/GuideArticles.vue"),
                          },
                          {
                            path: "",
                            name: "HelpGuideHouseholderPresentationGuideParentArticleParent",
                            component: () =>
                              import("@/js/pages/help/Guide.vue"),
                            children: [
                              {
                                path: ":slug?",
                                name: "HelpGuideHouseholderPresentationArticle",
                                component: () =>
                                  import("@/js/pages/help/Article.vue"),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "quoting",
                    name: "HelpGuideHouseholderQuoting",
                    component: () => import("@/js/pages/help/GuideParent.vue"),
                    props: { contentfulTag: "householderQuotingGuide" },
                    children: [
                      {
                        path: "",
                        name: "HelpGuideHouseholderQuotingGuideParent",
                        children: [
                          {
                            path: "all",
                            name: "HelpGuideHouseholderQuotingGuideParentAll",
                            component: () =>
                              import("@/js/pages/help/GuideArticles.vue"),
                          },
                          {
                            path: "",
                            name: "HelpGuideHouseholderQuotingGuideParentArticleParent",
                            component: () =>
                              import("@/js/pages/help/Guide.vue"),
                            children: [
                              {
                                path: ":slug?",
                                name: "HelpGuideHouseholderQuotingArticle",
                                component: () =>
                                  import("@/js/pages/help/Article.vue"),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "technical-visit",
                    name: "HelpGuideHouseholderTechnicalVisit",
                    component: () => import("@/js/pages/help/GuideParent.vue"),
                    props: { contentfulTag: "householderTechnicalVisitGuide" },
                    children: [
                      {
                        path: "",
                        name: "HelpGuideHouseholderTechnicalVisitGuideParent",
                        children: [
                          {
                            path: "all",
                            name: "HelpGuideHouseholderTechnicalVisitGuideParentAll",
                            component: () =>
                              import("@/js/pages/help/GuideArticles.vue"),
                          },
                          {
                            path: "",
                            name: "HelpGuideHouseholderTechnicalVisitGuideParentArticleParent",
                            component: () =>
                              import("@/js/pages/help/Guide.vue"),
                            children: [
                              {
                                path: ":slug?",
                                name: "HelpGuideHouseholderTechnicalVisitArticle",
                                component: () =>
                                  import("@/js/pages/help/Article.vue"),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "contract",
                    name: "HelpGuideHouseholderContract",
                    component: () => import("@/js/pages/help/GuideParent.vue"),
                    props: { contentfulTag: "householderContractGuide" },
                    children: [
                      {
                        path: "",
                        name: "HelpGuideHouseholderContractGuideParent",
                        children: [
                          {
                            path: "all",
                            name: "HelpGuideHouseholderContractGuideParentAll",
                            component: () =>
                              import("@/js/pages/help/GuideArticles.vue"),
                          },
                          {
                            path: "",
                            name: "HelpGuideHouseholderContractGuideParentArticleParent",
                            component: () =>
                              import("@/js/pages/help/Guide.vue"),
                            children: [
                              {
                                path: ":slug?",
                                name: "HelpGuideHouseholderContractArticle",
                                component: () =>
                                  import("@/js/pages/help/Article.vue"),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "administration",
                    name: "HelpGuideHouseholderAdministration",
                    component: () => import("@/js/pages/help/GuideParent.vue"),
                    props: { contentfulTag: "householderAdministrationGuide" },
                    children: [
                      {
                        path: "",
                        name: "HelpGuideHouseholderAdministrationGuideParent",
                        children: [
                          {
                            path: "all",
                            name: "HelpGuideHouseholderAdministrationGuideParentAll",
                            component: () =>
                              import("@/js/pages/help/GuideArticles.vue"),
                          },
                          {
                            path: "",
                            name: "HelpGuideHouseholderAdministrationGuideParentArticleParent",
                            component: () =>
                              import("@/js/pages/help/Guide.vue"),
                            children: [
                              {
                                path: ":slug?",
                                name: "HelpGuideHouseholderAdministrationArticle",
                                component: () =>
                                  import("@/js/pages/help/Article.vue"),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "installation",
                    name: "HelpGuideHouseholderInstallation",
                    component: () => import("@/js/pages/help/GuideParent.vue"),
                    props: { contentfulTag: "householderInstallationGuide" },
                    children: [
                      {
                        path: "",
                        name: "HelpGuideHouseholderInstallationGuideParent",
                        children: [
                          {
                            path: "all",
                            name: "HelpGuideHouseholderInstallationGuideParentAll",
                            component: () =>
                              import("@/js/pages/help/GuideArticles.vue"),
                          },
                          {
                            path: "",
                            name: "HelpGuideHouseholderInstallationGuideParentArticleParent",
                            component: () =>
                              import("@/js/pages/help/Guide.vue"),
                            children: [
                              {
                                path: ":slug?",
                                name: "HelpGuideHouseholderInstallationArticle",
                                component: () =>
                                  import("@/js/pages/help/Article.vue"),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "legal",
                    name: "HelpGuideHouseholderLegal",
                    component: () => import("@/js/pages/help/GuideParent.vue"),
                    props: { contentfulTag: "householderLegalGuide" },
                    children: [
                      {
                        path: "",
                        name: "HelpGuideHouseholderLegalGuideParent",
                        children: [
                          {
                            path: "all",
                            name: "HelpGuideHouseholderLegalGuideParentAll",
                            component: () =>
                              import("@/js/pages/help/GuideArticles.vue"),
                          },
                          {
                            path: "",
                            name: "HelpGuideHouseholderLegalGuideParentArticleParent",
                            component: () =>
                              import("@/js/pages/help/Guide.vue"),
                            children: [
                              {
                                path: ":slug?",
                                name: "HelpGuideHouseholderLegalArticle",
                                component: () =>
                                  import("@/js/pages/help/Article.vue"),
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "career",
        name: "CareerParent",
        component: () => import("@/js/pages/career/Index.vue"),
        children: [
          {
            path: "",
            name: "CareerHome",
            component: () => import("@/js/pages/career/Home.vue"),
            meta: {
              title: "CareerHome.title",
              description: "CareerHome.description",
            },
          },
          {
            path: "posts",
            name: "CareerJobPosts",
            component: () => import("@/js/pages/career/Jobs.vue"),
            meta: {
              title: "CareerJobPosts.title",
              description: "CareerJobPosts.description",
            },
          },
          {
            path: "post/:id",
            name: "CareerJobPost",
            component: () => import("@/js/pages/career/Post.vue"),
          },
          {
            path: "post/:id/apply",
            name: "CareerJobPostApply",
            component: () => import("@/js/pages/career/Apply.vue"),
          },
        ],
      },
      {
        path: "ambassadors",
        name: "AmbassadorsParent",
        component: () => import("@/js/pages/ambassadors/Index.vue"),
        children: [
          {
            path: "",
            name: "AmbassadorsHome",
            component: () => import("@/js/pages/ambassadors/Home.vue"),
            meta: {
              title: "AmbassadorsHome.title",
              description: "AmbassadorsHome.description",
            },
          },
          {
            path: "lead",
            name: "AmbassadorsLead",
            component: () => import("@/js/pages/ambassadors/LandBot.vue"),
          },
          {
            path: "about",
            name: "AmbassadorsAbout",
            component: () => import("@/js/pages/ambassadors/About.vue"),
          },
        ],
      },
      {
        path: "installer",
        name: "InstallerParent",
        component: () => import("@/js/pages/installer/Index.vue"),
        children: [
          {
            path: "",
            alias: "home",
            name: "InstallerHome",
            component: () => import("@/js/pages/installer/Home.vue"),
            meta: {
              title: "InstallerHome.title",
              description: "InstallerHome.description",
            },
          },
          {
            path: "administration",
            name: "InstallerAdministration",
            component: () => import("@/js/pages/installer/Administration.vue"),
            meta: {
              title: "InstallerAdministration.title",
              description: "InstallerAdministration.description",
            },
          },
          {
            path: "digital-sales-tools",
            name: "InstallerDigitalSalesTools",
            component: () =>
              import("@/js/pages/installer/DigitalSalesTools.vue"),
            meta: {
              title: "InstallerDigitalSalesTools.title",
              description: "InstallerDigitalSalesTools.description",
            },
          },
          {
            path: "find-new-clients",
            name: "InstallerFindNewClients",
            component: () => import("@/js/pages/installer/FindNewClients.vue"),
            meta: {
              title: "InstallerFindNewClients.title",
              description: "InstallerFindNewClients.description",
            },
          },
          {
            path: "case-studies",
            name: "InstallerCaseStudiesParent",
            component: () =>
              import("@/js/pages/installer/case-studies/Index.vue"),
            children: [
              {
                path: "",
                name: "InstallerCaseStudiesHome",
                component: () =>
                  import("@/js/pages/installer/case-studies/home/Index.vue"),
                children: [
                  {
                    path: "",
                    name: "InstallerCaseStudiesHomeOverview",
                    component: () =>
                      import(
                        "@/js/pages/installer/case-studies/home/Overview.vue"
                      ),
                    meta: {
                      title: "InstallerCaseStudiesHomeOverview.title",
                      description:
                        "InstallerCaseStudiesHomeOverview.description",
                    },
                  },
                  {
                    path: "all",
                    name: "InstallerCaseStudiesHomeAll",
                    component: () =>
                      import("@/js/pages/installer/case-studies/home/All.vue"),
                    meta: {
                      title: "InstallerCaseStudiesHomeAll.title",
                      description: "InstallerCaseStudiesHomeAll.description",
                    },
                  },
                ],
              },
              {
                path: ":slug",
                name: "InstallerCaseStudy",
                component: () =>
                  import("@/js/pages/installer/case-studies/Entry.vue"),
              },
            ],
          },
          {
            path: "lead",
            name: "InstallerLeadParent",
            children: [
              {
                path: "",
                name: "InstallerLeadLandbot",
                component: () =>
                  import("@/js/pages/installer/lead/Landbot.vue"),
              },
            ],
          },
          {
            path: "pricing",
            name: "Pricing",
            component: () => import("@/js/pages/installer/Pricing.vue"),
            meta: {
              title: "Pricing.title",
              description: "Pricing.description",
            },
          },
        ],
      },
      {
        path: "blog",
        name: "BlogParent",
        children: [
          {
            path: "",
            name: "Blog",
            component: () => import("@/js/pages/blog/Index.vue"),
            meta: {
              title: "Blog.title",
              description: "Blog.description",
            },
            children: [
              {
                path: "",
                name: "BlogHome",
                component: () => import("@/js/pages/blog/Home.vue"),
              },
              {
                path: "series",
                name: "BlogSeries",
                component: () => import("@/js/pages/blog/Series.vue"),
              },
            ],
          },
          {
            path: "article/:slug",
            name: "BlogArticle",
            component: () => import("@/js/pages/blog/Article.vue"),
          },
        ],
      },
      {
        path: "tools",
        name: "ToolsParent",
        component: () => import("@/js/pages/tools/Index.vue"),
        children: [
          {
            path: "",
            alias: "home",
            name: "ToolsHome",
            component: () => import("@/js/pages/tools/Home.vue"),
            meta: {
              title: "ToolsHome.title",
              description: "ToolsHome.description",
            },
          },
          {
            path: "cout-travaux-enedis",
            name: "ToolEnedisConstructionCost",
            component: () =>
              import("@/js/pages/tools/EnedisConstructionCost.vue"),
            meta: {
              title: "ToolEnedisConstructionCost.title",
              description: "ToolEnedisConstructionCost.description",
            },
          },
          {
            path: "impact-climatique",
            name: "ToolCarbonModelCalculator",
            component: () =>
              import("@/js/pages/tools/CarbonModelCalculator.vue"),
            meta: {
              title: "ToolCarbonModelCalculator.title",
              description: "ToolCarbonModelCalculator.description",
            },
          },
          {
            path: "verifier-devis",
            name: "ToolQuoteChecker",
            component: () => import("@/js/pages/tools/QuoteChecker.vue"),
            meta: {
              title: "ToolQuoteChecker.title",
              description: "ToolQuoteChecker.description",
            },
          },
          {
            path: "zone-abf",
            name: "ToolHistoricalMonumentChecker",
            component: () =>
              import("@/js/pages/tools/HistoricalMonumentChecker.vue"),
            meta: {
              title: "ToolHistoricalMonumentChecker.title",
              description: "ToolHistoricalMonumentChecker.description",
            },
          },
        ],
      },
      {
        path: "dynamic-forms",
        name: "DynamicFormsParent",
        component: () => import("@/js/pages/dynamic-forms/Index.vue"),
        children: [
          {
            path: "administration",
            name: "DynamicFormAdministration",
            component: () =>
              import("@/js/pages/dynamic-forms/Administration.vue"),
          },
          {
            path: "user",
            name: "DynamicFormLead",
            component: () => import("@/js/pages/dynamic-forms/Lead.vue"),
          },
          {
            path: "enedis-consumption-permission",
            name: "DynamicFormEnedisConsumptionPermission",
            component: () =>
              import(
                "@/js/pages/dynamic-forms/EnedisConsumptionPermission.vue"
              ),
          },
        ],
      },
      {
        path: "potentiel-solaire",
        alias: "lead",
        name: "LeadLandbot",
        component: () => import("@/js/pages/lead/Landbot.vue"),
      },
      {
        path: "support",
        name: "Support",
        component: () => import("@/js/pages/support/Home.vue"),
        meta: {
          title: "Support.title",
          description: "Support.description",
        },
      },
      {
        path: "support/schedule/:slug",
        name: "SupportSchedule",
        component: () => import("@/js/pages/support/Schedule.vue"),
      },
      {
        path: "signup",
        alias: "register",
        name: "SignUp",
        component: () => import("@/js/pages/SignUp.vue"),
        meta: {
          title: "SignUp.title",
          description: "SignUp.description",
        },
      },
      {
        path: "signup/callback",
        alias: "register/callback",
        name: "SignUpCallback",
        component: () => import("@/js/pages/signup/Callback.vue"),
        meta: {
          title: "SignUpCallback.title",
          description: "SignUpCallback.description",
        },
      },
      {
        path: "signup/enable/callback",
        name: "EnableAccountCallback",
        component: () => import("@/js/pages/signup/EnableAccountCallback.vue"),
        meta: {
          title: "EnableAccountCallback.title",
          description: "EnableAccountCallback.description",
        },
      },
      {
        path: "signin",
        alias: "login",
        name: "SignIn",
        component: () => import("@/js/pages/SignIn.vue"),
        meta: {
          title: "SignIn.title",
          description: "SignIn.description",
        },
      },
      {
        path: "signin/admin",
        alias: "login/admin",
        name: "AdminSignIn",
        component: () => import("@/js/pages/signin/AdminSignIn.vue"),
        meta: {
          title: "AdminSignIn.title",
          description: "AdminSignIn.description",
        },
      },
      {
        path: "signin/supplier",
        alias: "login/supplier",
        name: "SupplierSignIn",
        component: () => import("@/js/pages/signin/SupplierSignIn.vue"),
        meta: {
          title: "SupplierSignIn.title",
          description: "SupplierSignIn.description",
        },
      },
      {
        path: "ambassadors/signin",
        alias: "login/ambassadors",
        name: "AmbassadorSignIn",
        component: () => import("@/js/pages/AmbassadorSignIn.vue"),
        meta: {
          title: "AmbassadorSignIn.title",
          description: "AmbassadorSignIn.description",
        },
      },
      {
        path: "reset-password",
        name: "ResetPassword",
        component: () => import("@/js/pages/ResetPassword.vue"),
        meta: {
          title: "ResetPassword.title",
          description: "ResetPassword.description",
        },
      },
      {
        path: "reset-password/supplier",
        name: "ResetPasswordSupplier",
        component: () =>
          import("@/js/pages/reset-password/SupplierResetPassword.vue"),
        meta: {
          title: "ResetPasswordSupplier.title",
          description: "ResetPasswordSupplier.description",
        },
      },
      {
        path: "reset-password/callback",
        name: "ResetPasswordCallback",
        component: () => import("@/js/pages/reset-password/Callback.vue"),
        meta: {
          title: "ResetPasswordCallback.title",
          description: "ResetPasswordCallback.description",
        },
      },
      {
        path: "invite/installer/callback",
        name: "InviteCallbackInstaller",
        component: () =>
          import("@/js/pages/signup/InstallerTeamMemberInviteCallback.vue"),
        meta: {
          title: "InviteCallbackInstaller.title",
          description: "InviteCallbackInstaller.description",
        },
      },
      {
        path: "invite/supplier/callback",
        name: "InviteCallbackSupplier",
        component: () =>
          import("@/js/pages/signup/SupplierSubuserInviteCallback.vue"),
        meta: {
          title: "InviteCallbackSupplier.title",
          description: "InviteCallbackSupplier.description",
        },
      },
      {
        path: "invite/user/callback",
        name: "InviteCallbackUser",
        component: () => import("@/js/pages/signup/UserInviteCallback.vue"),
        meta: {
          title: "InviteCallbackUser.title",
          description: "InviteCallbackUser.description",
        },
      },
      {
        path: ":pathMatch(.*)*",
        name: "PageNotFound",
        component: () => import("@/js/pages/PageNotFound.vue"),
      },
      {
        path: "france",
        name: "ReportsParent",
        children: [
          {
            path: "",
            name: "ReportsHome",
            component: () => import("@/js/pages/report/Home.vue"),
            meta: {
              title: "ReportsHome.title",
              description: "ReportsHome.description",
            },
          },
          {
            path: "2023",
            name: "Reports2023",
            children: [
              {
                path: "",
                name: "Reports2023Home",
                component: () => import("@/js/pages/report/2023/Overview.vue"),
              },
              {
                path: "introduction",
                name: "Reports2023Introduction",
                component: () =>
                  import("@/js/pages/report/2023/Introduction.vue"),
              },
              {
                path: "market-evolution",
                name: "Reports2023MarketEvolution",
                component: () =>
                  import("@/js/pages/report/2023/MarketEvolution.vue"),
              },
              {
                path: "barometre-des-consommateurs",
                name: "Reports2023BarometreConsommateurs",
                component: () =>
                  import("@/js/pages/report/2023/BarometreConsommateurs.vue"),
              },
              {
                path: "hardware",
                name: "Reports2023Hardware",
                component: () => import("@/js/pages/report/2023/Hardware.vue"),
              },
              {
                path: "reglementation",
                name: "Reports2023Reglementation",
                component: () =>
                  import("@/js/pages/report/2023/Reglementation.vue"),
              },
              {
                path: "ecodelinquance",
                name: "Reports2023Ecodelinquance",
                component: () =>
                  import("@/js/pages/report/2023/Ecodelinquance.vue"),
              },
              {
                path: "co2-energie-mix",
                name: "Reports2023Co2EnergyMix",
                component: () =>
                  import("@/js/pages/report/2023/Co2EnergyMix.vue"),
              },
              {
                path: "a-propos-projetsolaire",
                name: "Reports2023AboutProjetsolaire",
                component: () =>
                  import("@/js/pages/report/2023/AboutProjetsolaire.vue"),
              },
            ],
          },
        ],
      },
    ],
  },
]

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (to.hash && savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ el: to.hash, behavior: "smooth" })
        }, 500)
      })
    } else if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ el: to.hash, behavior: "smooth" })
        }, 500)
      })
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  history: history,
  routes,
})

router.beforeEach(async (to, from, next) => {
  const { title, description } = to.meta
  const lang = to.params.lang || i18n.global.locale.value
  if (lang) {
    i18n.global.locale.value = lang
  }
  if (title && description) {
    useHead({
      title: i18n.global.t(`meta-tags.${title}`),
      link: [
        {
          rel: "canonical",
          href: `${import.meta.env.VITE_BASE_URL}${to.fullPath}`,
        },
      ],
      meta: [
        {
          name: "description",
          content: i18n.global.t(`meta-tags.${description}`),
        },
        {
          name: "og:title",
          content: i18n.global.t(`meta-tags.${title}`),
        },
        {
          name: "og:description",
          content: i18n.global.t(`meta-tags.${description}`),
        },
        {
          name: "og:type",
          content: "website",
        },
        {
          name: "og:image",
          content: import.meta.env.VITE_OG_IMAGES_LANDING,
        },
      ],
    })
  }
  next()
})

export default router
