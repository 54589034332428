const state = () => ({
  user: null,
  token: null,
  expiresAt: null,
  issuedAt: null,
  type: null,
  roles: [],
})

const actions = {
  login({ commit }, { email, password, type }) {
    let endpoint
    delete axios.defaults.headers.common["Authorization"]

    if (type === "householder") endpoint = "/auth/user/login"
    else if (type === "installer") endpoint = "/auth/installer/login"
    else if (type === "admin") endpoint = "/auth/admin/login"
    else if (type === "supplier") endpoint = "/auth/supplier/login"
    else throw new Error("Invalid user type used to login")
    return axios
      .post(endpoint, {
        email,
        password,
      })
      .then((response) => {
        const auth = {
          user: response.data.user,
          token: response.data.token,
          expiresAt: new Date(response.data.expires_at).getTime(),
          issuedAt: new Date(response.data.issued_at).getTime(),
          type,
          roles: response.data.roles,
        }

        localStorage.setItem("auth", JSON.stringify(auth))
        window.axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${auth.token}`

        commit("login", auth)

        return auth
      })
  },

  register({ commit }, { params, type }) {
    let endpoint
    if (type === "householder") endpoint = "/auth/user/register"
    else if (type === "installer") endpoint = "/auth/installer/register"
    else if (type === "admin") endpoint = "/auth/admin/register"
    else throw new Error("Invalid user type used to register")
    return axios.post(endpoint, params).then((response) => {
      console.log(response)
    })
  },

  logout({ commit }) {
    localStorage.removeItem("auth")
    delete window.axios.defaults.headers.common["Authorization"]
    commit("logout")
  },
}

const mutations = {
  login(state, payload) {
    state.user = payload.user
    state.token = payload.token
    state.expiresAt = payload.expiresAt
    state.issuedAt = payload.issuedAt
    state.type = payload.type
    state.roles = payload.roles
  },

  logout(state) {
    state.user = null
    state.installer = null
    state.token = null
    state.expiresAt = null
    state.issuedAt = null
    state.type = null
    state.roles = null
  },
}

const getters = {
  isAuthenticated: (state) => state.token && state.expiresAt > Date.now(),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
