const state = () => ({
  items: [],
})

const actions = {
  fetch({ commit }, { id }) {
    return axios.get(`/installer-directory/${id}`).then((response) => {
      commit("storeItem", response.data)
    })
  },
}

const mutations = {
  storeItem(state, item) {
    state.items = unionState(state.items, [item])
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
